const dictionary = {
  login: {
    booking_number: {
      en: "Booking Number",
      de: "Buchungsnummer",
    },
    mail: {
      en: "Email Address",
      de: "E-Mail Adresse",
    },
    log: {
      en: "Log in",
      de: "Anmelden",
    },
    wait: {
      en: "Loading...",
      de: "Laden...",
    },
    title: {
      en: "Raus Companion",
      de: "Raus Companion",
    },
    text: {
      en: "Find all travel information at a glance. Log in with your email address and booking number.",
      de: "Hier findest du alle Reiseinformationen auf einen Blick. Melde dich mit deiner E-Mail Adresse und deiner Buchungsnummer an.",
    },
    loggedIn: {
      en: "Successfully logged in",
      de: "Erfolgreich eingeloggt",
    },
    mailError: {
      en: "Please enter a valid email address.",
      de: "Bitte gib eine gültige E-Mail Adresse ein.",
    },
    bookingError: {
      en: "Sorry, we can't log you in without booking number.",
      de: "Es tut uns leid, wir können dich nicht ohne Buchungsnummer einloggen.",
    },
    error: {
      en: "Sorry, that did not work. Make sure you are using the correct email address and booking number.",
      de: "Das hat leider nicht funktioniert. Versichere dich, dass du die richtige E-Mail Adresse und die richtige Buchungsnummer verwendest.",
    },
    unexpectedError: {
      en: "We encountered an unexpected login error. Please try again. If the error persists, contact us at hello@raus.life.",
      de: "Es gab einen unerwarteten Anmeldefehler. Bitte versuche es erneut. Falls der Fehler weiterhin auftritt, kontaktiere uns unter hello@raus.life.",
    },
    expired: {
      en: "Your session has expired. Please log in again.",
      de: "Deine Sitzung ist abgelaufen. Bitte melde dich erneut an.",
    },
  },

  technicalOutage: {
    title: {
      en: "Technical outage",
      de: "Technische Störung",
    },
    subtitle: {
      de: "Wir haben zurzeit ein kleines technisches Problem mit unserem Raus Companion",
      en: "We are currently experiencing a small technical problem with our Raus Companion",
    },
    messageBoxTitle: {
      de: "Keine bange, dein Aufenthalt in der Cabin findet weiterhin statt",
      en: "No worries, your stay in the cabin will still take place",
    },
    messageBoxText: {
      de: `Alle information zu deiner <strong>Anreise</strong> und den <strong>Türcode</strong> findest du in der <strong>Email</strong>, die wir dir 2 Tage vor deiner Anreise zuschicken.`,
      en: `All information about your <strong>arrival</strong> and the <strong>door code</strong> can be found in the <strong>email</strong> that we will send you 2 days before your arrival.`,
    },
    disclaimer: {
      de: `Als Referenz: <a href='https://share.cleanshot.com/h5qb5Jb5' target={"_blank"}> So sieht die email ungefähr aus</a>`,
      en: `As a reference: <a href='https://share.cleanshot.com/h5qb5Jb5' target={"_blank"}> This is what the email looks like</a>`,
    },
    contactTitle: {
      de: "BEI WEITEREN FRAGEN ERREICHST DU UNS JEDERZEIT:",
      en: "IF YOU HAVE ANY FURTHER QUESTIONS, YOU CAN REACH US AT ANY TIME:",
    },
    viaMail: {
      de: "Per E-Mail: hello@raus.life",
      en: "Via email: hello@raus.life",
    },
    viaPhone: {
      de: "Per Telefon: +49 30-57713570 (Montag - Freitag, 9-18 Uhr)",
      en: "Via phone: +49 30-57713570 (Monday - Friday, 9-18 o'clock)",
    },
    inEmergencies: {
      de: "In dringenden Fällen: +49 1573-5987706 (rund um die Uhr erreichbar)",
      en: "In urgent emergencies: +49 1573-5987706 (available around the clock)",
    },
  },

  pages: {
    booking: {
      en: "Booking details",
      de: "Buchungsdetails",
    },
    packlist: {
      en: "Packing list",
      de: "Packliste",
    },
    pets: {
      en: "Bring your dog",
      de: "Nimm deinen Hund mit",
    },
    shuttle: {
      de: "Buche den Shuttle-Service vom Bahnhof zur Cabin",
      en: "Book the shuttle service from the train station to the cabin",
    },
    mobility: {
      en: "Book a SHARE NOW/Free2Move car for your trip",
      de: "Buche ein SHARE NOW/Free2Move Auto für deine Anreise",
    },
    klim: {
      en: "Support Regional Climate Protection!",
      de: "Unterstütze regionalen Klimaschutz!",
    },
    overview: {
      en: "WIFI & inventory",
      de: "WLAN & was du wo findest",
    },
    emergency: {
      en: "In an emergency",
      de: "Im Notfall",
    },
    helpingHandGuide: {
      en: "Guide",
      de: "Guide",
    },
    safety: {
      en: "Safety",
      de: "Sicherheit",
    },
    toilet: {
      en: "How to use the toilet",
      de: "Wie man die Toilette benutzt",
    },
    doors: {
      en: "How to open doors & windows",
      de: "Wie man Türen und Fenster öffnet",
    },
    water: {
      en: "Electricity & water",
      de: "Strom & Wasser",
    },
    heating: {
      en: "Heating & ventilation",
      de: "Heizung & Lüftung",
    },
    stove: {
      en: "How to use stove & make fire",
      de: "Wie man den Ofen bedient",
    },
    sauna: {
      en: "Sauna",
      de: "Sauna",
    },
    hottub: {
      en: "Hot Tub",
      de: "Hot Tub",
    },
    generator: {
      en: "Generator guide",
      de: "Generator Guide",
    },
    grill: {
      en: "How to use the gas grill",
      de: "Wie man den Gasgrill bedient",
    },
    tips: {
      en: "Explore the region",
      de: "Tipps für die Region",
    },
    rate: {
      en: "Booked rate",
      de: "Gebuchte Rate",
    },
    cancellationFee: {
      en: "Cancelation Conditions",
      de: "Stornierungsbedingungen",
    },
    noShowFee: {
      en: "No-Show Conditions",
      de: "Nicht-Erscheinen Konditionen",
    },
    findCabin: {
      en: "Find & open your cabin",
      de: "Deine Cabin finden & öffnen",
    },
    cabinRules: {
      en: "Cabin rules",
      de: "Cabin Regeln",
    },
    checkIn: {
      en: "Check in",
      de: "Einchecken",
    },
    checkOut: {
      en: "Checkout",
      de: "Check-out",
    },
    connection: {
      en: "Arrival",
      de: "Anreise",
    },
    contact: {
      en: "Contact",
      de: "Kontakt",
    },
    contactHost: {
      en: "Contact host",
      de: "Kontaktiere GastgeberInnen ",
    },
    welcomeTitle: {
      en: "Hello",
      de: "Hallo",
    },
    welcomeIntro: {
      en: "a very warm welcome to Raus!",
      de: "willkommen bei Raus!",
    },
    defaultWelcomeText: {
      en: "We will accompany you in the next few days and provide you with all the details about arrival and departure, your stay and your cabin.",
      de: "Wir begleiten dich in den nächsten Tagen und stellen dir hier alle Details zu An- und Abreise, deinem Aufenthalt und deiner Cabin zur Verfügung.",
    },
    welcomeText: {
      en: "Get ready for your upcoming stay and unlock all the important information about arrival and departure, your stay and your cabin by checking in.",
      de: "Mache dich bereit für deinen bevorstehenden Aufenthalt und erhalte Zugang zu allen Details zu An- und Abreise, deinem Aufenthalt und deiner Cabin, indem du eincheckst.",
    },
    shortWelcomeTitle: {
      en: "Hi",
      de: "Hallo",
    },
    shortWelcomeText: {
      en: "as we can see, you have booked more than one cabin with us. Please select one reservation below and find out all the details about your upcoming stay.",
      de: "wie wir sehen können, hast du mehr als eine Cabin bei uns gebucht. Bitte wähle eine Buchung aus, um alle Details zu deinem bevorstehenden Aufenthalt zu erfahren.",
    },
    welcomeOutro: {
      en: "We wish you a wonderful and relaxing time out in nature!",
      de: "Wir wünschen dir eine tolle, erholsame Zeit in der Natur.",
    },
    defaultWelcomeButton: {
      en: "Got it!",
      de: "Alles klar!",
    },
    welcomeButton: {
      en: "Check in now",
      de: "Jetzt einchecken",
    },
    checkinLater: {
      en: "I will check in later",
      de: "Mache ich später",
    },
    afterTitle: {
      en: "Thanks for staying with us,",
      de: "Danke für deinen Aufenthalt mit uns,",
    },
    afterIntro: {
      en: "we hope you were able to make some valuable memories during your stay.",
      de: "wir hoffen, du konntest während deines Aufenthalts wertvolle Erinnerungen sammeln.",
    },
    afterText: {
      en: "If you would like to share one of these moments with family and friends, please help us with your feedback. As a thank you, we'll provide you with a small voucher code that you're encouraged to pass on.",
      de: "Wenn du Lust hast, einen dieser Momente mit Familie und Freund*innen zu teilen, hilf uns mit deinem Feedback. Als Dankeschön stellen wir dir einen kleinen Gutscheincode zur Verfügung, bei dem Weiterverschenken ausdrücklich erwünscht ist.",
    },
    afterOutro: {
      en: "Thank you for your stay with Raus! We hope to welcome you back to one of our cabins soon.",
      de: "Vielen Dank für deinen Aufenthalt mit Raus! Wir hoffen, dich bald wieder in einer unserer Cabins willkommen zu heißen.",
    },
    afterButton: {
      en: "Share your feedback with us",
      de: "Teile dein Feedback mit uns",
    },
    extrasPageIntro: {
      en: "Choose between food options, (nature) experiences and other extras to customize your stay:",
      de: "Wähle zwischen Verpflegungsoptionen, Erlebnissen und weiteren Extras, um deinen Aufenthalt noch individueller zu gestalten:",
    },
    toReservation: {
      en: "To your Cabin",
      de: "Zu Deiner Cabin",
    },
    urgentFaqs: {
      en: "Urgent FAQs",
      de: "Häufige Fragen",
    },
  },

  contents: {
    wifi: {
      en: "Wifi",
      de: "WLAN",
    },
    bookExtraButton: {
      en: "Book now",
      de: "Jetzt buchen",
    },
    totalPrice: {
      en: "in total",
      de: "insgesamt",
    },
    paymentExtra: {
      en: "If you have booked your cabin with the credit card, it will also be automatically charged for the booking this extra. Otherwise, you will receive a payment link via email.",
      de: "Sofern du die Cabin mit deiner Kreditkarte bezahlt hast, wird diese auch für die Buchung dieses Extras belastet. Andernfalls senden wir dir einen Zahlungslink per E-Mail.",
    },
    totalPricePaid: {
      en: "paid in total",
      de: "insgesamt bezahlt",
    },
    totalPaid: {
      en: "Confirmed & paid",
      de: "Bestätigt & bezahlt",
    },
    beBooked: {
      en: "This extra can be booked up to",
      de: "Dieses Extra kann bis zu",
    },
    wasBooked: {
      en: "This extra could be booked up to",
      de: "Dieses Extra konnte nur bis zu",
    },
    hours: {
      en: "hours",
      de: "Stunden",
    },
    days: {
      en: "days",
      de: "Tage",
    },
    hoursBefore: {
      en: "before arrival",
      de: "vor der Anreise gebucht werden",
    },
    daysBefore: {
      en: "before arrival",
      de: "vor der Anreise gebucht werden",
    },
    toBooking: {
      en: "Book",
      de: "Zur Buchung",
    },
    extraBooked: {
      en: "This extra was booked",
      de: "Dieses Extra wurde",
    },
    extraBookedF: {
      en: "Booking confirmed",
      de: "Buchung bestätigt",
    },
    extraPerPersonNight: {
      en: "per person per night",
      de: "pro Person pro Nacht",
    },
    extraPerPersonStay: {
      en: "per person per stay",
      de: "pro Person pro Aufenthalt",
    },
    extraPerCabinStay: {
      en: "per cabin per stay",
      de: "pro Cabin pro Aufenthalt",
    },
    extraPerCabinNight: {
      en: "per cabin per night",
      de: "pro Cabin pro Nacht",
    },
    bookedExtraDetails: {
      en: "View details",
      de: "Details ansehen",
    },
    bookableExtraDetails: {
      en: "View details & book",
      de: "Details ansehen & buchen",
    },
    bookExtraNow: {
      en: "Book now",
      de: "Jetzt buchen",
    },
    person: {
      en: "person",
      de: "Person",
    },
    persons: {
      en: "persons",
      de: "Personen",
    },
    multiply: {
      en: "x ",
      de: "x ",
    },
    night: {
      en: "night",
      de: "Nacht",
    },
    nights: {
      en: "nights",
      de: "Nächte",
    },
    freeExtra: {
      en: "Book for free",
      de: "Kostenfrei buchbar",
    },
    quantitySelected: {
      en: "selected amount",
      de: "ausgewählte Anzahl",
    },
    quantitySpecific2: {
      en: "The total price is based on the selected amount of the product.",
      de: "Der Gesamtpreis basiert auf der ausgewählten Anzahl des Extras.",
    },
    quantitySpecific4: {
      en: "The total price is based on the number of persons and the selected amount of the product.",
      de: "Der Gesamtpreis basiert auf der Anzahl der Personen und der ausgewählten Anzahl des Extras.",
    },
    moreQuantity: {
      en: "Choose the amount",
      de: "Wähle hier die Anzahl",
    },
    totalPriceH: {
      en: "Total price:",
      de: "Gesamtpreis:",
    },
    wifiName: {
      en: "Raus Wifi:",
      de: "Raus WLAN:",
    },
    locationTitle: {
      en: "Location",
      de: "Standort",
    },
    cabinTitle: {
      en: "Cabin",
      de: "Cabin",
    },
    noCabinAssigned: {
      en: "Your reservation has been received and is still being processed. We are on it! Please check back again later.",
      de: "Deine Reservierung ist eingegangen und wird noch bearbeitet. Wir sind dran! Bitte schau zu späterem Zeitpunkt noch einmal vorbei.",
    },
    wifiPassword: {
      en: "Password:",
      de: "Passwort:",
    },
    wifiText: {
      en: "Please be aware that the internet is a bit more sedate from time to time, which can sometimes be a little reminder to switch off.",
      de: "Bitte sei dir bewusst, dass das Internet ab und zu etwas gemächlicher ist – was manchmal wiederum eine kleine Erinnerung daran sein kann, abzuschalten.",
    },
    noBookedExtras: {
      en: "You have not booked any extras yet. To book extras for your stay, please contact us at",
      de: "Du hast noch keine Extras gebucht. Um Extras für deinen Aufenthalt zu buchen, wende dich gerne an uns unter",
    },
    bookMoreExtras: {
      en: "To book additional extras for your stay, please contact us at",
      de: "Um zusätzliche Extras für deinen Aufenthalt zu buchen, wende dich gerne an uns unter",
    },
    fBExtrasIntro: {
      en: "Together with selected partners, we bring culinary highlights directly to your cabin. Our offerings, based on availability, consist of regional and seasonal products whenever possible. You don't have to carry anything: the packages will be waiting for you in your cabin upon arrival or will be delivered in portions during longer stays.",
      de: "Gemeinsam mit ausgewählten PartnerInnen bringen wir kulinarische Highlights direkt zu dir in die Cabin. Unsere Angebote bestehen je nach Verfügbarkeit aus möglichst regionalen und saisonalen Produkten. Du musst nichts schleppen: Die Pakete erwarten dich bei Ankunft in deiner Cabin oder werden bei längeren Aufenthalten portionsweise geliefert.",
    },
    activitiesExtrasIntro: {
      en: "Enhance your stay by adding local experiences and activities to make your getaway even more special. Some experiences can be booked directly, while others are requested from our local partners and confirmed based on availability.",
      de: "Füge deinem Aufenthalt lokale Erlebnisse & Aktivitäten hinzu, um deine Auszeit noch besonderer zu machen. Manche Erlebnisse kannst du direkt buchen, andere werden bei unseren lokalen PartnerInnen angefragt und je nach Verfügbarkeit bestätigt.",
    },
    code: {
      en: "Door code",
      de: "Türcode",
    },
    codeLenzen: {
      en: "Door- and gate code",
      de: "Tür- und Torcode",
    },
    codeBox: {
      en: "Keybox code",
      de: "Schlüsselbox Code",
    },
    codeBike: {
      en: "Bike code",
      de: "Fahrrad Code",
    },
    codeEBike: {
      en: "E-Bike code",
      de: "E-Bike Code",
    },
    codeBBQ: {
      en: "BBQ code",
      de: "Grill Code",
    },
    codeCart: {
      en: "handcart code",
      de: "Bollerwagen Code",
    },
    checkin: {
      en: "At the entrance to your cabin, you will find an electronic door lock with a keypad. Please enter your door code there.",
      de: "Am Eingang deiner Cabin, befindet sich ein elektronisches Türschloss mit Keypad. Bitte gib dort deinen Türcode ein.",
    },
    cabin: {
      en: "My cabin",
      de: "Meine Cabin",
    },
    arrival: {
      en: "Arrival",
      de: "Anreise",
    },
    bookConditions: {
      en: "I accept ",
      de: "Ich akzeptiere die ",
    },
    conditions: {
      en: "general terms and conditions.",
      de: "allgemeinen Geschäftsbedingungen.",
    },
    conditionsError: {
      en: "Please accept the general terms and conditions",
      de: "Bitte akzeptiere die allgemeinen Geschäftsbedingungen",
    },
    preferredTimeError: {
      en: "Please select your preferred time",
      de: "Bitte wähle deine gewünschte Zeit",
    },
    exPrice: {
      en: "Price",
      de: "Preis",
    },
    exPriceFinal: {
      en: "See payment details",
      de: "Zahlungsdetails ansehen",
    },
    exQty: {
      en: "Amount",
      de: "Anzahl",
    },
    exDescription: {
      en: "Description",
      de: "Beschreibung",
    },
    exQuantity: {
      en: "Available amount",
      de: "Verfügbare Anzahl",
    },
    exNoteTitle: {
      en: "Please note that this Extra is not available on these dates",
      de: "Bitte beachten Sie, dass dieses Extra an diesen Daten nicht verfügbar ist",
    },
    arrivalFrom: {
      en: "from",
      de: "ab",
    },
    arrivalClock: {
      en: "",
      de: "Uhr",
    },
    departure: {
      en: "Departure",
      de: "Abreise",
    },
    departureUntil: {
      en: "until",
      de: "bis",
    },
    departureClock: {
      en: "",
      de: "Uhr",
    },
    from: {
      en: "From",
      de: "Ab",
    },
    until: {
      en: "Until",
      de: "Bis",
    },
    cabinCoordinates: {
      en: "Cabin coordinates",
      de: "Cabin Koordinaten",
    },
    tbaPlural: {
      en: "Parking coordinates, directions, the exact location of your cabin and tips for the region will be provided two days before arrival.",
      de: "Die Parkplatzkoordinaten, Anreisebeschreibung, der genaue Standort deiner Cabin sowie Tipps für die Region werden dir zwei Tage vor deiner Anreise mitgeteilt.",
    },
    // tba = to be announced
    codeError: {
      en: "No door code available. Please contact us at <a href='tel:+4915735987706'>+4915735987706</a> and we'll help you.",
      de: "Kein Türcode verfügbar. Bitte kontaktiere uns unter <a href='tel:+4915735987706'>+4915735987706</a> und wir helfen dir.",
    },
    tba: {
      en: "Your door code will be provided two days before arrival",
      de: "Der Türcode wird dir zwei Tage vor deiner Anreise mitgeteilt",
    },
    tbaNotCheckedIn: {
      en: "Please finish the online check-in first",
      de: "Bitte schließe zuerst den Online Check-in ab",
    },
    tbaSecurity: {
      en: "Useful information on how to stay safe and get help if needed during your stay will be provided two days before arrival.",
      de: "Nützliche Informationen für den Notfall und die Sicherheit während deines Aufenthalts in der Cabin werden dir zwei Tage vor deiner Anreise mitgeteilt.",
    },
    missingCoordinates: {
      en: "Please check the arrival description",
      de: "Bitte orientiere dich an der Anreisebeschreibung",
    },

    tbaInfo: {
      en: "16 days ahead of your arrival we will provide you here with useful tips and information to prepare your stay. The exact location and directions to your cabin can be found here 2 days ahead of stay.",
      de: "16 Tage vor deiner Anreise geben wir dir hier nützliche Tips und Informationen zur Vorbereitung deines Aufenthalts. Den genauen Standort deiner Cabin sowie Wegbeschreibung findest du hier 2 Tage vor deiner Anreise.",
    },
    parkingCoordinates: {
      en: "Parking coordinates",
      de: "Parkplatz Koordinaten",
    },
    address: {
      en: "Address",
      de: "Adresse",
    },
    confirmation: {
      en: "Booking",
      de: "Buchung",
    },
    bookedExtras: {
      en: "Extras",
      de: "Extras",
    },
    waitExtraBooking: {
      en: "Is being booked...",
      de: "Wird gebucht...",
    },
    extraBooked: {
      en: "The extra has been booked",
      de: "Das Extra wurde gebucht",
    },
    waitExtrasLoading: {
      en: "Almost there, booking magic in progress...",
      de: "Fast geschafft, deine Anfrage geht gerade Raus ... ",
    },
    waitRequestLoading: {
      en: "Your request is being processed...",
      de: "Deine Anfrage wird verschickt...",
    },
    where: {
      en: "Where",
      de: "Wo",
    },
    basicFactsTitle: {
      en: "My stay & check-in",
      de: "Mein Aufenthalt & Check-in",
    },
    meditationStart: {
      en: "Start meditation by Michaela Aue",
      de: "Meditation mit Michaela Aue starten",
    },
    vdsStart: {
      en: "Start Stargazing Experience",
      de: "Sternenhimmel Erlebnis starten",
    },
    mobilityStart: {
      en: "Start your Share now",
      de: "Starte jetzt dein Share now",
    },
    fireTitle: {
      en: "Note on wildfire risk",
      de: "Hinweis zur Waldbrandgefahrenstufe",
    },
    fireText: {
      en: "Please be aware that the current wildfire risk level in the region of your cabin is ",
      de: "Aktuell gilt in der Region deiner Cabin die Waldbrandgefahrenstufe ",
    },
    fireSubtext: {
      en: "We therefore ask you not to make an open fire under any circumstances and to always put out cigarettes in the ashtray provided. If you have any questions, please do not hesitate to contact us via our telephone or email support.",
      de: "Wir bitten dich daher unter keinen Umständen ein offenes Feuer zu machen und Zigaretten immer im vorhandenen Aschenbecher auszumachen. Wenn du Fragen dazu hast, kontaktiere uns gerne jederzeit über unseren Telefon- oder Mail-Support.",
    },
    fireSubtextSauna: {
      en: "Sauna usage is prohibited when the forest fire level is at 4 or higher.",
      de: "Ab Waldbrandstufe 4 ist die Saunanutzung nicht mehr erlaubt.",
    },
  },

  atoms: {
    logout: {
      en: "Log out",
      de: "Abmelden",
    },
    close: {
      en: "Close",
      de: "Schliessen",
    },
  },

  checkIn: {
    personalInfo: {
      en: "Please submit the online checkin form to receive the door code of your cabin.",
      de: "Bitte schließe den Online Check-In ab, um den Türcode deiner Cabin zu erhalten.",
    },
    personHeader: {
      en: "Personal data",
      de: "Personenbezogene Daten",
    },
    personExplainer: {
      en: "Add all guests who will be staying in the cabin.",
      de: "Füge alle Gäste hinzu, die in der Cabin übernachten werden.",
    },
    bookingHeader: {
      en: "Booking information",
      de: "Buchungsinformationen",
    },
    signatureHeader: {
      en: "Signature",
      de: "Unterschrift",
    },
    addressHeader: {
      en: "Address",
      de: "Adresse",
    },
    firstName: {
      en: "First Name",
      de: "Vorname",
    },
    lastName: {
      en: "Last Name",
      de: "Familienname",
    },
    dateOfBirth: {
      en: "Date of Birth",
      de: "Geburtsdatum",
    },
    dateOfBirthDescription: {
      en: "Format date as 18.01.1989",
      de: "Datum im Format 18.01.1989",
    },
    idNumber: {
      en: "ID number",
      de: "Ausweisnummer",
    },
    idNumberDescription: {
      en: "Passport Number or ID card serial number",
      de: "Pass- oder Personalausweisseriennummer",
    },
    addressDetail: {
      en: "Address Details",
      de: "Adressen Details",
    },
    address: {
      en: "Street name and house number",
      de: "Straße und Hausnummer",
    },
    addressExplainer: {
      en: "The address of the person who booked the cabin",
      de: "Die Adresse der Person die gebucht hat",
    },
    postcode: {
      en: "Postcode",
      de: "Postleitzahl",
    },
    country: {
      en: "Country",
      de: "Land",
    },
    nationality: {
      en: "Nationality",
      de: "Staatsangehörigkeit",
    },
    city: {
      en: "City",
      de: "Stadt",
    },
    bookingDetail: {
      en: "Booking Details",
      de: "Buchungsdetails",
    },
    bookingReference: {
      en: "Booking Reference",
      de: "Buchungsreferenz",
    },
    checkInDate: {
      en: "Checkin date",
      de: "Check-In Datum",
    },
    checkOutDate: {
      en: "Checkout date",
      de: "Check-Out Datum",
    },
    signature: {
      en: "Signature",
      de: "Signatur",
    },
    signatureExplainer: {
      en: "Draw your signature in the box below",
      de: "Zeichne deine Unterschrift in das Feld unten",
    },
    clearSig: {
      en: "Clear signature",
      de: "Unterschrift zurücksetzen",
    },
    addGuest: {
      en: "Add guest",
      de: "Gast hinzufügen",
    },
    removeGuest: {
      en: "Remove guest",
      de: "Gast entfernen",
    },
    submit: {
      en: "Check in",
      de: "Einchecken",
    },
    finishCheckin: {
      en: "Complete online checkin",
      de: "Online Check-In abschließen",
    },
    fillRequiredFieldsDisclaimer: {
      en: "Please fill out all fields and provide a signature",
      de: "Bitte fülle alle Felder aus und zeichne deine Unterschrift",
    },
    messsageCheckInSuccessful: {
      en: "Checkin was successful",
      de: "Check-In war erfolgreich",
    },
    messageCheckInFailed: {
      en: "Checkin failed. Please try again",
      de: "Check-In ist fehlgeschlagen. Bitte versuche es erneut",
    },
  },

  contentItems: {
    beforeTrip: {
      title: {
        en: "Preparation & Arrival",
        de: "Vorbereitung & Anreise",
      },
    },

    wifi: {
      title: {
        en: "Door code & Wifi",
        de: "Türcode & WLAN",
      },
    },
    food: {
      title: {
        en: "Book Food & Beverage",
        de: "Essen & Getränke buchen",
      },
    },
    activities: {
      title: {
        en: "Book Experiences",
        de: "Erlebnisse buchen",
      },
    },
    myStay: {
      title: {
        en: "Your cabin",
        de: "Deine Cabin",
      },
      reportIncident: {
        en: "Report an incident",
        de: "Defekt melden",
      },
      reportedIncident: {
        en: "Thanks, the incident has been reported",
        de: "Danke! Der Defekt wurde gemeldet",
      },
    },
    address: {
      title: {
        en: "Getting there",
        de: "Die Anreise",
      },
    },
    cabinRules: {
      title: {
        en: "Departure & checkout",
        de: "Abreise & Check-out",
      },
    },
    help: {
      title: {
        en: "Help & Safety",
        de: "Hilfe & Sicherheit",
      },
    },
    helpingHandGuide: {
      title: {
        en: "Helping Hand Guide",
        de: "Helping Hand Guide",
      },
    },
    urgentFaqs: {
      title: {
        en: "Helping Hand Guide",
        de: "Helping Hand Guide",
      },
    },
    basicFacts: {
      title: {
        en: "My stay",
        de: "Mein Aufenthalt",
      },
    },
    checkout: {
      title: {
        en: "Departure",
        de: "Abreise",
      },
    },
    bookedCabins: {
      title: {
        en: "Booked cabins",
        de: "Gebuchte Cabins",
      },
    },
  },

  extraContentItems: {
    food: {
      title: {
        en: "Food & Beverage",
        de: "Essen & Getränke",
      },
      hasBookings: {
        en: "Booked food & beverage",
        de: "Gebuchte Essen & Getränke",
      },
      noBookings: {
        en: "You have not booked any food & beverage yet",
        de: "Du hast noch keine Essen & Getränke gebucht",
      },
      toBookingsButton: {
        en: "Book food & beverage",
        de: "Essen & Getränke buchen",
      },
      bookMore: {
        en: "Book more food & beverage",
        de: "Weitere Essen & Getränke buchen",
      },
    },
    activity: {
      title: {
        en: "Experiences",
        de: "Erlebnisse",
      },
      hasBookings: {
        en: "Booked experiences",
        de: "Gebuchte Erlebnisse",
      },
      noBookings: {
        en: "You have not booked any experiences yet",
        de: "Du hast noch keine Erlebnisse gebucht",
      },
      pendingBookings: {
        en: "Pending bookings",
        de: "Warten auf Bestätigung",
      },
      deniedBookings: {
        en: "Unfortunately could not be booked",
        de: "Konnte leider nicht gebucht werden",
      },
      toBookingsButton: {
        en: "Book experiences",
        de: "Erlebnisse buchen",
      },
      bookMore: {
        en: "Book more experiences",
        de: "Weitere Erlebnisse buchen",
      },
      pendingText: {
        en: "We are in contact with our partner to check the availability of the offer. We'll be in touch with you shortly.",
        de: "Wir sind in Kontakt mit dem Anbieter, um die Verfügbarkeit des Angebots zu prüfen. Wir informieren dich, sobald wir eine Rückmeldung erhalten haben.",
      },
      pendingTitle: {
        en: "Pending confirmation",
        de: "Bestätigung ausstehend",
      },
      deniedText: {
        en: "We are sorry, but we were not able to arrange this activity for you. We apologize for any disappointment and invite you to explore our other extras and activities.",
        de: "Leider war es nicht möglich, diese Aktivität für dich zu arrangieren. Schaue dir gern unsere weiteren Extras und Aktivitäten an, um eine Alternative auszusuchen.",
      },
      deniedTitle: {
        en: "Unfortunately this activity could not be booked",
        de: "Diese Aktivität konnte leider nicht gebucht werden",
      },
      activityConfirmed: {
        en: "This activity was confirmed",
        de: "Diese Aktivität wurde",
      },
      confirmedText: {
        en: "The extra has been successfully booked.",
        de: "Das Extra wurde erfolgreich hinzugefügt.",
      },
      activityConfirmedFor: {
        en: "for",
        de: "für",
      },
      activityConfirmedPrice: {
        en: "",
        de: "bestätigt",
      },
      activityDuration: {
        en: "Duration",
        de: "Dauer",
      },
      activityDate: {
        en: "Date",
        de: "Datum",
      },
      activityTime: {
        en: "Time",
        de: "Uhrzeit",
      },
      preferredActivityTime: {
        en: "Preferred time of the day",
        de: "Bevorzugter Zeitraum",
      },
      preferredActivityTimeChoose: {
        en: "Please choose",
        de: "Bitte auswählen",
      },
      preferredActivityTimeFlexible: {
        en: "Flexible",
        de: "Flexibel",
      },
      preferredActivityTimeAM: {
        en: "Morning",
        de: "Vormittag",
      },
      preferredActivityTimePM: {
        en: "Afternoon",
        de: "Nachmittag",
      },
      dateTimeTbc: {
        en: "Consider us your activity matchmakers. We'll work our magic with our partner to lock in your desired time for this activity and will inform you as soon as we have the confirmation.",
        de: "Wir informieren dich über die genaue Uhrzeit und Datum für diese Aktivität, sobald wir die Bestätigung von unserem Partner haben.",
      },
      activityParticipants: {
        en: "Participants",
        de: "Teilnehmer",
      },
      activityMeeting: {
        en: "Meeting point",
        de: "Treffpunkt",
      },
      activityRequested: {
        en: "Requested & waiting for confirmation",
        de: "Angefragt & warten auf Bestätigung",
      },
      activityBooked: {
        en: "Booked & paid",
        de: "Gebucht & bezahlt",
      },
      activityNotBooked: {
        en: "Could not be booked",
        de: "Konnte leider nicht gebucht werden",
      },
      activityGTK: {
        en: "Good to know",
        de: "Gut zu wissen",
      },
      activityDistance: {
        en: "Distance from cabin",
        de: "Entfernung von der Cabin",
      },
      activityNote: {
        en: "Please note",
        de: "Bitte beachte",
      },
      activityHost: {
        en: "About the host",
        de: "Über den Host",
      },
      activityContact: {
        en: "Contact details",
        de: "Kontakt",
      },
    },
    offers: {
      title: {
        en: "Preparation & Arrival",
        de: "Vorbereitung & Anreise",
      },
      hasBookings: {
        en: "Booked mobility & offers",
        de: "Gebuchte Mobilität & Angebote",
      },
      noBookings: {
        en: "You have not booked any mobility & offers yet",
        de: "Du hast noch keine Mobilität & Angebote gebucht",
      },
      toBookingsButton: {
        en: "Book mobility & offers",
        de: "Mobilität & Angebote buchen",
      },
      bookMore: {
        en: "Book more mobility & offers",
        de: "Weitere Mobilität & Angebote buchen",
      },
    },
  },

  stayLonger: {
    title: {
      en: "Take more time for yourself",
      de: "Nimm dir noch mehr Zeit für dich",
    },
    textPossible: {
      en: `We've checked our calendar and it looks like you can spend another night with Raus.`,
      de: `Wir haben in unserem Kalender gesehen, dass du noch eine Nacht länger mit uns Raus kannst.`,
    },
    subtextPossible: {
      en: `To give you more time to unwind in nature, we'd like to split the costs with you.For an additional night, we'll only charge <strong>%1 EUR</strong>.`,
      de: `Damit du länger in der Natur entspannen kannst, möchten wir uns die Kosten mit dir teilen. Für eine Extra Nacht berechnen wir dann nur<strong> %1 EUR</strong>.`,
    },
    paymentSubtextPossible: {
      en: `If you have booked your cabin with a credit card, it will be automatically charged for the booking this extra. Otherwise, you will receive a payment link via email.`,
      de: `Sofern du die Cabin mit deiner Kreditkarte bezahlt hast, wird diese nach der Bestätigung auch für die Buchung dieses Extras belastet. Andernfalls senden wir dir einen Zahlungslink per E-Mail.`,
    },
    textInProgress: {
      en: `Your request to extend your stay with us is being processed.`,
      de: `Wir bearbeiten gerade deine Anfrage länger mit uns Raus zu bleiben.`,
    },
    textCompletedExtended: {
      en: `Good news! We were able to book your extended stay with us!`,
      de: `Deine Anfrage länger mit uns Raus zu bleiben konnte gebucht werden!`,
    },
    textCompletedNotPossible: {
      en: `We are sorry to inform you that we could not accommodate your request to extend your stay with us.`,
      de: `Deine Anfrage länger mit uns Raus zu bleiben konnte leider nicht erfüllt werden.`,
    },
    optionBefore: {
      en: "I would like to come one day earlier",
      de: "Ich möchte einen Tag vorher kommen",
    },
    optionBeforeExplainer: {
      en: "Check-in would be on %1",
      de: "Check-in wäre dann am %1",
    },
    optionAfter: {
      en: "I would like to stay one day longer",
      de: "Ich möchte einen Tag länger bleiben",
    },
    optionAfterExplainer: {
      en: "Check-out would be on %1",
      de: "Check-out wäre dann am %1",
    },
    optionBoth: {
      en: "I would like both",
      de: "Ich möchte beides",
    },
    submitButton: {
      en: "Request extended stay",
      de: "Verlängerung anfragen",
    },
    conditionsError: {
      en: "Request failed. Please select one of the options below.",
      de: "Anfrage fehlgeschlagen. Bitte wähle eine der unten stehenden Optionen.",
    },
    reqestSent: {
      en: "Request sent",
      de: "Die Anfrage wurde gesendet",
    },
    remindLater: {
      en: "Remind me later",
      de: "Später erinnern",
    },
    notInterested: {
      en: "Not interested",
      de: "Kein Interesse",
    },
    notAvailableAnymore: {
      en: "You can only extend your stay until the day before departure.",
      de: "Ein Verlängerung deines Aufenthalts ist nur bis einen Tag vor Abreise möglich.",
    },
  },

  lateCheckOut: {
    title: {
      en: "Stay until 6pm on your departure day",
      de: "Bleibe am Abreisetag bis 18 Uhr",
    },
    textPossible: {
      en: `Do you want to spend longer in bed? No problem! Book your Late Check-out now and instead of checking out in the morning at 11 am, you can leave in the evening at 6 pm. For the Late Check-out, we will only charge <strong>%1 EUR</strong>.`,
      de: `Du möchtest länger im Bett verbringen? Kein Problem! Buche jetzt deinen Late Check-out und reise statt vormittags um 11 Uhr, abends um 18 Uhr ab. Für das Late Check-out berechnen wir dann nur<strong> %1 EUR</strong>.`,
    },
    booked: {
      en: `Your Check-Out has been extended until 6 pm!`,
      de: `Dein Check-Out wurde auf 18 Uhr verlängert!`,
    },
    toBookLateCheckOutExtra: {
      en: "I want to stay until 6 pm",
      de: "Ich möchte bis 18 Uhr bleiben",
    },
    optionAfterExplainer: {
      en: "Check-out would be on %1",
      de: "Check-out wäre dann am %1",
    },
    remindLater: {
      en: "Remind me later",
      de: "Später erinnern",
    },
    notInterested: {
      en: "Not interested",
      de: "Kein Interesse",
    },
    notAvailableAnymore: {
      en: "Late Checkout is only bookable until the day before departure.",
      de: "Der Late Late Checkout ist nur bis einen Tag vor Abreise Buchbar.",
    },
  },

  pricingInfo: {
    proPersonPerPerson: {
      en: "per person",
      de: "pro Person",
    },
    proAufenthaltPerStay: {
      en: "per stay",
      de: "pro Aufenthalt",
    },
    proStuckPerUnit: {
      en: "per unit",
      de: "pro Stück",
    },
    proPersonProTagPerPersonPerDay: {
      en: "per person per day",
      de: "pro Person pro Tag",
    },
    proTagPerDay: {
      en: "per day",
      de: "pro Tag",
    },
  },

  quantityInfo: {
    furZweiPersonenUndEineMahlzeitForTwoPeopleAndOneMeal: {
      en: "for two people and one meal",
      de: "für zwei Personen und eine Mahlzeit",
    },
    furZweiPersonenUndMehrAlsEineMahlzeitForTwoPeopleAndMoreThanOneMeal: {
      en: "for two people and more than one meal",
      de: "für zwei Personen und mehr als eine Mahlzeit",
    },
    furZweiPersonenUndZweiMahlzeitenForTwoPeopleAndTwoMeals: {
      en: "for two people and two meals",
      de: "für zwei Personen und zwei Mahlzeiten",
    },
    furEinePersonUndEineMahlzeitForOnePersonAndOneMeal: {
      en: "for one person and one meal",
      de: "für eine Person und eine Mahlzeit",
    },
    furEinePersonUndMehrAlsEineMahlzeitForOnePersonAndMoreThanOneMeal: {
      en: "for one person and more than one meal",
      de: "für eine Person und mehr als eine Mahlzeit",
    },
    furDenGesamtenAufenthaltForTheEntireStay: {
      en: "for the entire stay",
      de: "für den gesamten Aufenthalt",
    },
    furEinenTagForOneDay: {
      en: "for one day",
      de: "für einen Tag",
    },
  },

  hostInfo: {
    title: {
      en: "Your Hosts",
      de: "Deine GastgeberInnen",
    },
    nameAndTeam: {
      en: "%1 & team",
      de: "%1 & Team",
    },
    description: {
      en: "%1 and their team are your hosts on-site. If you have any concerns during your stay, please feel free to reach out to them directly.",
      de: "Bei Anliegen während deines Aufenthalts wende dich bitte direkt an deine GastgeberInnen vor Ort.",
    },
    phone: {
      en: "Phone",
      de: "Telefon",
    },
    languages: {
      en: "Speaks",
      de: "Spricht",
    },
  },

  languages: {
    en: {
      en: "English",
      de: "Englisch",
    },
    de: {
      en: "German",
      de: "Deutsch",
    },
  },
};

const DEBUG_MODE = false;

const dict = (id, lang, replace = null) => {
  const path = id.split(".");
  let term;

  try {
    term = path.reduce((d, cur) => {
      if (d === undefined || d === null) {
        throw new Error(`Invalid path: ${id}`);
      }
      return d[cur];
    }, dictionary);
  } catch (error) {
    console.error(`Translation error for '${id}': ${error.message}`);
    return id; // Return the original id if the path is invalid
  }

  if (DEBUG_MODE) console.log("term", id, term, lang);

  let translation = id;
  if (term && term[lang] !== null && term[lang] !== undefined) {
    translation = term[lang];
  } else {
    console.warn(`Missing translation for '${id}' in language '${lang}'`);
  }

  if (!replace) {
    return translation;
  }

  replace.forEach((str, i) => {
    translation = translation.replace(`%${i + 1}`, str);
  });

  return translation;
};

export default dict;
