import styled from "styled-components";
import caret from "../../../assets/images/caret.svg";
import check_circle from "../../../assets/images/check_circle.svg";
import down from "../../../assets/images/keyboard_arrow_down.svg";
import radioChecked from "../../../assets/images/radio_button_checked.svg";
import radioUnchecked from "../../../assets/images/radio_button_unchecked.svg";

import {
  h3Alt,
  h4,
  h5,
  h5GreenSmall,
  p,
  pLarge,
  pLargeGreen,
  pSmall,
} from "../../../pages/styles/Typography";

export const ContentBox = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const InfoBox = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0px 0 16px;

  img {
    max-width: 100%;
  }
`;

export const InfoBoxWithMarginTop = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: 16px 0 0 0;
  padding: 0px 0 16px;

  img {
    max-width: 100%;
  }
`;
export const InfoBoxWithMarginBottom = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0 0 16px 0;
  padding: 0px 0 16px;

  img {
    max-width: 100%;
  }
`;

export const Note = styled.div`
  margin-top: -16px;
  margin-bottom: -14px;

  &:empty {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const SuccessInfoBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center; // This will center the icon vertically.
  background: #006434;
  border-radius: 16px;
  color: #fff;
  padding: 16px;
  margin-top: ${({ noMargin }) => (noMargin ? "0px" : "20px")};

  ::before {
    content: url(${check_circle});
    margin-right: 12px;
  }
`;

export const SuccessInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SuccessInfoTitle = styled.div`
  ${h5}
  color: #fff;
  margin-bottom: ${({ noMargin }) => (noMargin ? "0px" : "4px")};
`;

export const ExtraLink = styled.div`
  ${pLarge}
  height: 56px;
  background: #bdda0e;
  color: #592db5;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 20px;
  margin-top: 20px;
`;

export const ImageContainer = styled.div`
  margin-top: 24px;
  position: relative;
  width: 99.8%;
  margin-left: 0.2%;
  padding-top: 50%; /* 2:1 ratio */

  img {
    position: absolute;
    border-radius: 4px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Image = styled.img`
  border-top-right-radius: 78px;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  max-width: 100%;
  max-height: 100%;
`;

export const CenterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 64px;
`;

export const Tile = styled.div`
  padding: 16px 0px 0px;
`;

export const H = styled.div`
  ${h4};
  line-height: 24px;

  // font-family: 'neue-haas-unica';
`;

export const HRegular = styled.div`
  ${h4};

  // font-family: 'neue-haas-unica';
`;

export const H3 = styled.div`
  ${h3Alt};
  font-size: 28px;
  line-height: 28px;
  padding: 16px 0px 16px;
`;

export const H3noP = styled.div`
  ${h3Alt};
  font-size: 28px;
  line-height: 28px;
  padding: 0px 0px 16px;
`;

export const P = styled.div`
  ${p};
  line-height: 20px;
  // font-family: 'neue-haas-unica';
`;

export const Px = styled.div`
  ${p};
  font-size: 16px;
  line-height: 20px;
`;

export const BoxWrapper = styled.div`
  padding: 0 0 16px;
`;

export const BoxWrapperNoP = styled.div`
  padding: 0 0 0px;
`;

export const DatesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px 15px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const DatesBox = styled.div`
  &:first-of-type {
    img {
      border-radius: 10px;
    }
`;

// export const StyledLi = styled.li`
//   display: flex;
//   align-items: flex-start;

//   ::before {
//     content: url(${caret});
//     margin: -3px;
//     margin-right: 5px;
//   }
// `

export const AlignIcon = styled.div`
  display: flex;
  gap: ${({ noPadding }) => (noPadding ? "12px" : "4px")};
  align-items: center;
`;

export const AlignIconTop = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
`;

export const BulletList = styled.div`
  display: flex;
  align-items: flex-start;

  ::before {
    content: url(${caret});
    margin: -3px;
    margin-right: 5px;
  }
`;

export const Text = styled.div.attrs((props) => ({
  className: `subpageContent ${props.italic ? "italic" : ""}`,
}))`
  h2 {
    ${h3Alt}
  }

  ol {
    padding-inline-start: 2rem;
  }

  ul {
    list-style: none;
    padding-inline-start: 0px;
    // padding-left: 16px;
    position: relative;
    list-style: none;
    margin-left: 0;
    padding-left: 1.2em;

    li {
      line-height: 26px;
      position: relative;

      ::before {
        position: absolute;
        left: -25px;
        top: 2px;
        content: url(${caret});
      }
      p {
        display: inline-block;
        margin: 0;
      }
    }

    // p {
    //   display: flex;
    //   align-items: flex-start;
    //   line-height: 20px;

    //   ::before {
    //     content: url(${caret});
    //     margin: -3px;
    //     margin-right: 5px;
    //   }
    // }
  }

  img {
    border-radius: 0.25rem;
  }

  iframe {
    max-width: 100%;
    max-height: auto;
  }

  a {
    color: inherit;
    cursor: pointer;
  }

  &.italic {
    font-style: italic;
  }
`;

export const ExternalLink = styled.a`
  text-decoration: underline !important;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #ffffff;
  border-radius: 16px;
`;

export const NoMarginP = styled.p`
  ${pSmall}
  color: ${(props) => props.color || "#000"};
  margin: 0px;
`;

export const TopMarginP = styled.p`
  ${pSmall}
  color: ${(props) => props.color || "#000"};
  margin-top: 12px;
  margin-bottom: 0px;
`;

export const ErrorMessage = styled.p`
  ${pSmall};
  color: #b90707 !important;
  margin-bottom: 10px;
`;

export const MarginP = styled.p`
  ${pSmall}
  margin: 0px 0px 12px 0px;
`;

export const UnderlinedP = styled(MarginP)`
  text-decoration: underline;
  cursor: pointer;
`;

export const UnderlinedPCentered = styled(MarginP)`
  text-decoration: underline;
  cursor: pointer;
  margin-top: 12px;
  margin-bottom: -2px;
  display: flex;
  justify-content: center;
`;

export const ThemedCardBox = styled.div`
  margin-top: ${({ noMargin }) => (noMargin ? "8px" : "20px")};
`;

export const ThemedCard = styled(Card)`
  margin-bottom: ${({ noMargin }) => (noMargin ? "0px" : "20px")};
  background: ${({ theme }) => {
    switch (theme) {
      case "blue":
        return "#A6DFFF";
      case "orange":
        return "#FCBD1C";
      case "red":
        return "#FFFFFF";
      case "green":
        return "#006434";
      default:
        return "#FFFFFF";
    }
  }};
  color: ${({ theme }) => {
    switch (theme) {
      case "blue":
        return "#23212C";
      case "red":
        return "#B90707";
      case "orange":
        return "#B318A4";
      case "green":
        return "#FFFFFF";
      default:
        return "#000";
    }
  }};
  border: ${({ theme }) =>
    theme === "red"
      ? "1px solid #B90707"
      : theme === "black"
      ? "1px solid #23212c"
      : "none"};

  & * {
    color: ${({ theme }) => {
      switch (theme) {
        case "blue":
          return "#23212C";
        case "red":
          return "#B90707";
        case "orange":
          return "#B318A4";
        case "green":
          return "#FFFFFF";
        default:
          return "#000";
      }
    }};
  }
`;

export const HSubtitle = styled.div`
  ${h5}
`;

export const CardS = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-top: 0px;
  padding-bottom: 0px;
  background: #ffffff;
  border-radius: 20px;
`;

export const StyledRadio = styled.input.attrs({ type: "radio" })`
  display: none;

  & + label.radio-label {
    padding-left: 24px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    background-image: url(${radioUnchecked});
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 16px;
  }

  &:checked + label.radio-label {
    background-image: url(${radioChecked});
  }
`;

export const StyledLabel = styled.label`
  ${pSmall}
  display: flex;
  align-items: center;
  padding-left: 24px;
  position: relative;
  cursor: pointer;
  background-image: url(${radioUnchecked});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 16px;

  &:before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
  }
`;

export const RadioBox = styled.div`
  :not(:last-child) {
    margin-bottom: 16px;
  }

  > div {
    margin-bottom: 12px;
  }
`;

export const ExtrasCardWrapper = styled.div`
  :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
`;

export const CardImage = styled.div`
  width: 100%;
  padding-bottom: 50%; // Maintain 2:1 aspect ratio
  position: relative; // Required for absolute positioning of the child img
  margin-bottom: 12px;

  img {
    border-radius: 4px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute; // Make img cover the entire CardImage container
    top: 0;
    left: 0;
  }
`;

export const CardText = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  width: 100%;
`;

export const CardTitle = styled.div`
  ${pLarge}
  line-height: 24px;
  margin-bottom: 8px;
`;

export const ThemedCardTitle = styled.div`
  ${h5}
  margin-bottom: ${({ noMargin }) => (noMargin ? "0px" : "6px")};
  color: ${({ theme }) => {
    switch (theme) {
      case "blue":
        return "#23212C";
      case "red":
        return "#B90707";
      case "green":
        return "#FFFFFF";
      case "orange":
        return "#B318A4";
      case "black":
        return "#23212c";

      default:
        return "#000";
    }
  }};
`;

export const CardPrice = styled.div`
  ${h5GreenSmall}
  line-height: 20px;
  margin-bottom: 8px;
`;

export const CardPriceL = styled.div`
  ${pLargeGreen}
  line-height: 20px;
  margin-bottom: 24px;
`;

export const QuantityInfo = styled.div`
  ${pSmall}
  color: #747474
`;

export const CardInfo = styled.div`
  ${pSmall}
  line-height: 20px;
  margin-bottom: 12px;
`;

export const ThemedCardInfo = styled.div`
  ${pSmall}
  line-height: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 8px;
`;

export const BookConditions = styled.div`
  margin: 32px 0 4px 0;
  display: flex;
  align-items: center;
  line-height: 20px;
  img {
    margin-right: 4px;
  }
  a {
    color: #006434;
  }
`;
export const BookActions = styled.div`
  margin: 32px 0;
`;

export const TextContainer = styled.div`
  align-items: row;
`;

export const ExtraBox = styled.div`
  margin-top: 16px;

  &.error {
    color: #b90707;
    margin-top: 0;
    white-space: pre-line;
  }
`;

export const ExtraBoxS = styled.div`
  margin-top: 8px;

  &.error {
    color: #b90707;
    margin-top: 0;
  }
`;

export const ButtonMarginTop = styled.div`
  margin-top: 24px;
  }
`;

export const NoteTitle = styled.div`
  margin-top: 5px;
  font-weight: bold;
  font-style: italic;
`;

export const HS = styled.div`
  ${h4};
  line-height: 24px;
  margin-bottom: -15px;

  // font-family: 'neue-haas-unica';
`;

export const HS2 = styled.div`
  ${h4};
  line-height: 24px;

  // font-family: 'neue-haas-unica';
`;

export const PWrapper = styled.div`
  margin-bottom: ${({ noMargin }) => (noMargin ? "0px" : "16px")};
`;

export const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const QuantityButtonPlus = styled.button`
  background-color: #ffffff;
  border: 2px solid #23212c;
  border-radius: 50%;
  cursor: pointer;
  font-size: 18px;
  height: 40px;
  width: 40px;
  outline: none;
  position: relative;
  color: #23212c;
  padding: 0;

  ::after {
    content: "+";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const QuantityButtonMinus = styled.button`
  background-color: #ffffff;
  border: 2px solid #23212c;
  border-radius: 50%;
  cursor: pointer;
  font-size: 18px;
  height: 40px;
  width: 40px;
  outline: none;
  position: relative;
  color: #23212c;
  padding: 0;

  ::after {
    content: "-";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:disabled {
    color: rgba(35, 33, 44, 0.3);
    border-color: rgba(35, 33, 44, 0.3);
    cursor: auto;
  }
`;

export const QuantityDisplay = styled.span`
  font-size: 18px;
  margin: 0 10px;
`;

export const ButtonText = styled.span`
  ${pLarge}
  color: #FFFFFF;
  padding: 0px 0px 4px;
`;

export const StyledSelect = styled.select`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 24px 8px 12px;
  gap: 10px;

  background: rgb(253, 249, 237);

  border: 1px solid #23212c;
  border-radius: 16px;
  margin-top: 4px;
  width: 80%;

  flex: none;
  order: 1;
  flex-grow: 0;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background-image: url(${down});
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;

  outline: ${(props) =>
    props.className === "invalid" ? "rgb(255 149 149) solid 2px" : "none"};
`;
