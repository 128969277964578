import { contents } from "data/ContentOutline";
import PageLayout from "layouts/PageLayout";
import { useAppContext } from "provider/AppProvider";
import { useReservationFromQueryParams } from "utils/helper";
import dict from "utils/translations";
import {
  BoxWrapper,
  ContentBox,
  ExternalLink,
  H,
  InfoBox,
  P,
  Text,
} from "../components/Contents/styles/Contents.styled";

function Directions() {
  const { lang } = useAppContext();
  const { reservationId, reservation } = useReservationFromQueryParams();
  if (!reservation) return null;

  const cabin = reservation.cabin;
  const location = reservation.location;
  const isLodgeAmSee = reservation.property.code === "SPOT29";

  const reservationArrivalDate = new Date(reservation.arrival);
  const currentDate = new Date();

  // Calculate the difference between the reservation arrival date and the current date in milliseconds
  const timeDiff = reservationArrivalDate.getTime() - currentDate.getTime();

  // Calculate the difference in days
  const daysDiff = timeDiff / (1000 * 3600 * 24);

  return (
    <PageLayout
      isHeaderSubPageVisible={contents[0].id === "beforeTrip"}
      title={dict("pages.connection", lang)}
      subTitle={dict("contentItems.beforeTrip.title", lang)}
      contentId="directions"
    >
      <Text
        className="subpageContent"
        dangerouslySetInnerHTML={{ __html: cabin?.parking?.directions }}
      />
      <Text
        className="subpageContent"
        dangerouslySetInnerHTML={{ __html: cabin?.coordinates?.directions }}
      />
      <BoxWrapper>
        <ContentBox>
          {cabin?.parking ? (
            <InfoBox>
              {daysDiff <= 1000 && (
                <>
                  <H>{dict("contents.parkingCoordinates", lang)}</H>
                  <P>
                    {(!cabin.use_location_parking && cabin.parking?.url) ||
                    (cabin.use_location_parking && location.parking?.url) ? (
                      <ExternalLink
                        href={
                          !cabin.use_location_parking
                            ? cabin.parking?.url
                            : location.parking?.url
                        }
                        target="_blank"
                      >
                        {!cabin.use_location_parking
                          ? `${cabin.parking?.latitude}, ${cabin.parking?.longitude}`
                          : `${location.parking?.latitude}, ${location.parking?.longitude}`}
                      </ExternalLink>
                    ) : (
                      <span>
                        {!cabin.use_location_parking
                          ? `${cabin.parking?.latitude},  ${cabin.parking?.longitude}`
                          : `${location.parking?.latitude}, ${location.parking?.longitude}`}
                      </span>
                    )}
                  </P>
                </>
              )}
            </InfoBox>
          ) : (
            <InfoBox>
              <H>{dict("contents.parkingCoordinates", lang)}</H>
              <P>{dict("contents.missingCoordinates", lang)}</P>
            </InfoBox>
          )}
          {isLodgeAmSee ? (
            <InfoBox>
              <H>
                {!cabin
                  ? dict("contents.locationTitle", lang)
                  : dict("contents.cabinTitle", lang)}
              </H>
              <P>
                {!cabin || !cabin?.name ? (
                  <>{location.name}</>
                ) : (
                  <>{`${location.name}: ${cabin.name}`}</>
                )}
              </P>
            </InfoBox>
          ) : cabin?.coordinates ? (
            <InfoBox>
              {daysDiff <= 1000 ? (
                <H>{dict("contents.cabinCoordinates", lang)}</H>
              ) : null}
              {daysDiff <= 1000 ? (
                <P>
                  <ExternalLink href={cabin?.coordinates?.url} target="_blank">
                    {`${cabin?.coordinates?.latitude}, ${cabin?.coordinates?.longitude}`}
                  </ExternalLink>
                </P>
              ) : (
                <P>{dict("contents.tbaPlural", lang)}</P>
              )}
            </InfoBox>
          ) : (
            <InfoBox>
              <H>{dict("contents.cabinCoordinates", lang)}</H>
              <P>{dict("contents.tbaPlural", lang)}</P>
            </InfoBox>
          )}
        </ContentBox>
      </BoxWrapper>
    </PageLayout>
  );
}

export default Directions;
