import axios from "axios";
// import axiosDebug from "axios-debug-log";
const qs = require("qs");

const METADATA_URL = process.env.REACT_APP_METADATA_API_ENPOINT;
const METADATA_TOKEN = process.env.REACT_APP_METADATA_TOKEN;

// Enable Axios debugging using interceptors
// axios.interceptors.request.use((request) => {
//   console.log("Starting Request", JSON.stringify(request, null, 2));
//   return request;
// });

// axios.interceptors.response.use((response) => {
//   console.log("Response:", JSON.stringify(response.data, null, 2));
//   return response;
// });

class MetadataService {
  async getLocationAndCabin(spotCode, unitId = false, locale = "en") {
    const query = {
      locale: "en",
      filters: {
        spot_code: {
          $eq: spotCode,
        },
      },
      populate: {
        localizations: {
          fields: [
            "name",
            "description",
            "locale",
            "location_url_on_raus_website",
          ],
        },
        overview_image: {
          populate: {
            image_mobile: true,
          },
        },
        parking: {
          populate: true,
        },
        cabins: {
          fields: [
            "name",
            "description",
            "nuki_fallback_code",
            "use_location_parking",
            "use_unit_group_categorization",
            "locale",
            "unit_id",
            "unit_group_code",
            "wifi_ssid",
            "wifi_password",
            "sku",
            "use_location_categorization",
            "max_person_count",
            "wbs_value",
            "wbs_last_updated_at",
            "wbs_decimal_lat",
            "wbs_decimal_long",
            "revault_location_id",
          ],
          populate: {
            parking: true,
            coordinates: true,
            overview_image: {
              populate: {
                image_mobile: true,
              },
            },
            localizations: {
              fields: ["name", "description", "locale"],
              populate: {
                coordinates: true,
              },
            },
          },
        },
      },
      fields: ["name", "description", "locale", "location_url_on_raus_website"],
    };
    const queryString = qs.stringify(query, { indexes: true });

    try {
      const response = await axios.get(
        `${METADATA_URL}locations?${queryString}`,
        {
          headers: { Authorization: "Bearer " + METADATA_TOKEN },
        }
      );

      let location = response?.data?.data[0]?.attributes;
      let cabin = null;

      // Find the cabin via unitId and remove other cabins
      if (unitId && location.cabins && location.cabins.data.length > 0) {
        const cabinIndex = location.cabins.data.findIndex(
          (c) => c.attributes.unit_id === unitId
        );
        if (cabinIndex !== -1) {
          cabin = location.cabins.data[cabinIndex].attributes;
          // Keep only the matching cabin
          location.cabins.data = [location.cabins.data[cabinIndex]];
        } else {
          // If no matching cabin is found, remove all cabins
          location.cabins.data = [];
        }
      }

      // Handle localization for location
      if (locale !== "en") {
        const localization = location.localizations.data.find(
          (loc) => loc.attributes.locale === locale
        );
        if (localization) {
          Object.assign(location, localization.attributes);
        }
      }

      // Handle localization for cabin
      if (cabin && locale !== "en") {
        const cabinLocalization = cabin.localizations.data.find(
          (loc) => loc.attributes.locale === locale
        );
        if (cabinLocalization) {
          Object.assign(cabin, cabinLocalization.attributes);
        }
      }

      // Clean up the response
      delete location.localizations;
      if (cabin) {
        delete cabin.localizations;
      }
      // Remove the cabins array from location if it exists
      if (location.cabins) {
        delete location.cabins;
      }

      return { location, cabin };
    } catch (error) {
      console.error("Error in getLocationAndCabin:", error);
      // Sentry.captureException(error);
      throw error; // Re-throw the error to be handled by the caller
    }
  }
}

export default new MetadataService();
